<template>
    <div>
        <video-layout>
            <section class="about-item-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="about-author-content pt-15">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li class="breadcrumb-item"><a href="#">Category</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            {{ this.newsData.attributes.name }}

                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="post-layout-top-content">
                                <div class="
                                              post-categories
                                              d-flex
                                              justify-content-between
                                              align-content-center
                                            ">
                                    <div class="categories-item">
                                        <span>{{ this.newsData.attributes.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="about-tab-btn mt-40">
                                <!-- <div class="archive-btn">
                                    <ul>
                                        <li>
                                            <span>Category: <span>{{ this.newsData.attributes.name }}</span></span>
                                        </li>
                                    </ul>
                                </div> -->
                                
                                <div class="about-post-items">
                                    <div class="row">
                                        <template v-for="(data, index) in categoryData.slice(0, 8)">
                                            <div :key="index" class="col-md-6">
                                                <div class="feature-news-item">
                                                    <div class="feature-news-thumb">
                                                        <router-link :to="'/videonews/' + data.id">
                                                            <img :src="'https://cms.tvwan.com.pg' + data.attributes.cover.data.attributes.formats.small.url"
                                                                alt="gallery" />
                                                            <div class="meta-categories">
                                                                <span>{{
                                                                    }}</span>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                    <div class="feature-news-content">
                                                        <p>
                                                            <router-link :to="'/videonews/' + data.id">
                                                                {{ truncateText(data.attributes.title, 100) }}
                                                            </router-link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="bussiness-btn mt-30">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="all-post-sidebar-style-2 mt-40">
                                <div class="most-view-box">
                                    <div class="section-title">
                                        <h3 class="title">Recent News</h3>
                                    </div>
                                    <template v-for="(data, index) in recentNews.slice(0, 5)">
                                        <div :key="index" class="international-post-items">
                                            <div class="international-item">
                                                <div class="meta-category">
                                                    <!-- <span>Source: {{ data.source.name }}</span> -->
                                                    <span>{{ new Date(data.attributes.createdAt).toLocaleDateString('en-GB')
                                                        }}</span>
                                                </div>
                                                <h5 class="title">
                                                    <!-- <router-link to="/posts/postOne">{{ data.title }}</router-link> -->
                                                    <router-link :to="'/news/' + (data.id)">{{
                                                        truncateText(data.attributes.title, 50)
                                                    }}</router-link>
                                                </h5>
                                                <div class="meta-user">
                                                    <span><i class="fal fa-user-circle"></i> {{
                                                        data.attributes.writtenBy
                                                    }}</span>
                                                </div>
                                                <div class="text-thumb d-flex align-items-center">
                                                    <p>
                                                        {{ truncateText(data.attributes.body, 80) }}
                                                    </p>
                                                    <div class="thumb">
                                                        <!-- <img src="@/assets/images/international-post-thumb.jpg" alt="" /> -->
                                                        <!-- <img :src="data.urlToImage" alt="business" /> -->
                                                        <!-- <img :src="data.urlToImage" alt="business"
                                                            :style="{ width: '200px', height: 'auto' }" /> -->
                                                        <img :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                                                            alt="business" />
                                                    </div>
                                                </div>
                                                <!-- <ul>
                                                    <li>
                                                        <a href="#"><i class="far fa-eye"></i> 53,346</a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i class="far fa-heart"></i> 53,346</a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i class="fas fa-share"></i> 53,346</a>
                                                    </li>
                                                </ul> -->
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="ad mt-40">
                                    <a href="/"><img
                                            src="https://cms.tvwan.com.pg/uploads/8196285962274064809_b44ca665d9.png"
                                            alt="" /></a>
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </video-layout>
    </div>
</template>

<script>
// import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import VideoLayout from "./VideoLayout.vue";
import Posts from "../Data/TrendingHomeThree";

import NewsService from "../../service/NewsService";

export default {
    components: { VideoLayout },
    data() {
        return {
            Posts: Posts.data,
            newsData: {
                attributes: {
                    name: null,
                    createdAt: null,
                    smallImg: null,
                },
            },
            categoryData: {},
            recentNews: {
                attributes: {
                    // thumbnail: null
                }
            },
        };
    },
    watch: {
        $route(to, from) {
            // Reload the page whenever the route parameter 'name' changes
            if (to.params.name !== from.params.name) {
                this.reloadPage();
            }
        },
    },
    created: function () {
        document.addEventListener("scroll", this.topToBottom);

        this.loadCategoryData(this.$route.params.name);
    },
    methods: {
        reloadPage() {
            location.reload();
        },
        loadCategoryData(name) {
            const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

            // Set the name parameter to newsData attributes name
            this.newsData.attributes.name = capitalizedName;

            //service call to fetch newsdata of specific category
            const newsService = new NewsService();
            newsService
                .getVideoNewsByCategory(name)
                .then((data) => {
                    this.categoryData = data;
                    // console.log(this.categoryData);
                })
                .catch((error) => {
                    console.error("Error fetching news:", error);
                });
        },
        async fetchRecentNews() {
            const newsService = new NewsService();
            await newsService.getRecentNews()
                .then((data) => {
                    this.recentNews = data;
                    // console.log(this.recentNews)
                })
                .catch((error) => {
                    console.error("Error fetching news:", error);
                });
        },
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + "...";
            }
            return text;
        },
    },
    mounted() {
        this.fetchRecentNews();
    }
};
</script>


<style></style>