import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeTwo from '../views/HomeTwo'

import Contact from '../views/Contact.vue'
import Complaint from '../views/Complaint.vue'
import About from '../views/About.vue'
import Privacy from '../views/Privacy.vue'
import fourOfour from '../components/404.vue'

import News from '../components/Posts/PostOne/index.vue'
import VNews from '../components/Posts/Video/index.vue'
import WorldNews from '../components/Posts/PostTwo/index.vue'
import Category from '../components/category/index.vue'
import VideoNews from '../components/category/videonews.vue'


import Finance from '../components/Finance/index.vue'
// import Login from '../components/Auth/index.vue'
// import SignUp from '../components/Auth/SignUp.vue'
// import ForgotPassword from '../components/Auth/ForgotPass.vue'
// import Profile from '../components/Auth/Profile.vue'

import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeTwo
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/complaint',
    name: 'Complaint',
    component: Complaint
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/404',
    name: '404',
    component: fourOfour
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News
  },
  {
    path: '/videonews/:id',
    name: 'VNews',
    component: VNews
  },
  {
    path: '/worldnews/:id',
    name: 'WorldNews',
    component: WorldNews
  },
  // {
  //   path: '/category/:id',
  //   name: 'Category',
  //   component: Category
  // }
  {
    path: '/category/:name',
    name: 'Category',
    component: Category
  },
  {
    path: '/v-category/:name',
    name: 'VideoNews',
    component: VideoNews
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/finance',
    name: 'Finance',
    component: Finance
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login,
  //   meta: {
  //     requiresGuest: true,
  //   },
  // },
  // {
  //   path: '/signup',
  //   name: 'SignUp',
  //   component: SignUp,
  //   meta: {
  //     requiresGuest: true,
  //   },
  // },
  // {
  //   path: '/forgotpassword',
  //   name: 'Forgot Password',
  //   component: ForgotPassword,
  //   meta: {
  //     requiresGuest: true,
  //   },
  // },
  // {
  //   path: '/profile',
  //   component: Profile,
  //   meta: {
  //     requiresAuth: true
  //   },
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest);

  onAuthStateChanged(auth, user => {// eslint-disable-line no-unused-vars
    // console.log(user.currentUser)
    // if (user != null) {
    //   next('/profile');
    // }
    const isAuthenticated = auth.currentUser;
    if (requiresAuth && !isAuthenticated) {
      next('/login');
    } else if (requiresGuest && isAuthenticated) {
      next('/');
    } else {
      next();
    }
  });



});


export default router
